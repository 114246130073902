var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"btn-box"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"用户user_id："}},[_c('el-input',{attrs:{"placeholder":"请输入用户user_id"},model:{value:(_vm.uid),callback:function ($$v) {_vm.uid=$$v},expression:"uid"}})],1),_c('el-form-item',{attrs:{"label":"订单号："}},[_c('el-input',{attrs:{"placeholder":"请输入订单号"},model:{value:(_vm.orderId),callback:function ($$v) {_vm.orderId=$$v},expression:"orderId"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.seach(1)}}},[_vm._v("搜索")])],1)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.jdList,"border":""}},[_c('el-table-column',{attrs:{"fixed":"","prop":"order_id","label":"编号","width":"150"}}),_c('el-table-column',{attrs:{"fixed":"","label":"用户user_id(user_key)","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.user_id)+" ( "+_vm._s(scope.row.user_key)+" ) ")]}}])}),_c('el-table-column',{attrs:{"prop":"order_sn","label":"订单号","width":"150"}}),_c('el-table-column',{attrs:{"label":"商品标题","prop":"item_title","width":"300"}}),_c('el-table-column',{attrs:{"prop":"pay_amount","label":"支付金额","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"user_commission","label":"用户佣金","width":"100px"}}),_c('el-table-column',{attrs:{"label":"下单订单"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("dateTime")(scope.row.order_time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"订单状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.status == 1
              ? 'green'
              : scope.row.status == 3
              ? 'blue'
              : scope.row.status == 4
              ? 'warn'
              : scope.row.status == 5 ||
                scope.row.status == 6 ||
                scope.row.status == 7
              ? 'red'
              : ''},[_vm._v(" "+_vm._s(_vm._f("status")(scope.row.status))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"200","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 1 || scope.row.status == 2)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.alterDialog(scope.row)}}},[_vm._v("修改")]):_vm._e(),(scope.row.status == 1)?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.settlement(scope.row.order_id)}}},[_vm._v("结算")]):_vm._e()]}}])})],1),_c('el-dialog',{attrs:{"title":"修改","visible":_vm.dialog,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialog=$event}}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"用户user_id"}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.unionid),callback:function ($$v) {_vm.unionid=$$v},expression:"unionid"}})],1),_c('el-form-item',{attrs:{"label":"佣金"}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.user_commission),callback:function ($$v) {_vm.user_commission=$$v},expression:"user_commission"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.alter}},[_vm._v("确 定")])],1)],1),_c('el-pagination',{attrs:{"background":"","layout":"total, sizes,prev, pager, next,jumper","total":_vm.total},on:{"size-change":_vm.sizeChange,"current-change":_vm.currentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }